var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ssl-service-configuration-slide-container" },
    [
      _c("span", { staticClass: "form-title mb-4" }, [
        _vm._v("\n    Certificate Signing Request (CSR)\n  "),
      ]),
      _c("ssl-service-form", {
        ref: "ssl-service-form",
        staticClass: "mt-2",
        attrs: { bus: _vm.bus },
        on: { sslCreationFailed: _vm.sslCreationFailed },
      }),
      _c(
        "div",
        { staticClass: "buttons mt-3 mb-0 d-flex flex-column flex-end" },
        [
          _c(
            "div",
            { staticClass: "slide-viewer-button-row mt-3 mb-0" },
            [
              _c("primary-button", {
                attrs: {
                  "button-text": _vm.buttonText,
                  disabled: _vm.creatingSSL,
                  "aria-label": _vm.buttonText + " button",
                },
                on: {
                  onclick: function ($event) {
                    return _vm.submitSSL()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }