<template>
  <div class="ssl-service-configuration-slide-container">
    <span class="form-title mb-4">
      Certificate Signing Request (CSR)
    </span>

    <ssl-service-form
      ref="ssl-service-form"
      class="mt-2"
      :bus="bus"
      @sslCreationFailed="sslCreationFailed"
    />

    <div class="buttons mt-3 mb-0 d-flex flex-column flex-end">
      <div class="slide-viewer-button-row mt-3 mb-0 ">
        <primary-button
          :button-text="buttonText"
          :disabled="creatingSSL"
          :aria-label="buttonText + ' button'"
          @onclick="submitSSL()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'SSLServiceConfiguration',
  components: {
    PrimaryButton:  () => import('@/components/StagelineV2/shared/PrimaryButton'),
    SslServiceForm: () => import('@/components/Services/SSL/SslServiceForm'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: null,
  },
  data () {
    return {
      bus: new Vue(),
      creatingSSL: false,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
    ...mapGetters('vouchers', [
      'voucherByProductCategory',
    ]),
    buttonText () {
      return this.slide?.config.continue_button.text || 'Continue'
    },
    sslVoucherRedeemed() {
      return this.voucherByProductCategory('domain-ssl')?.redeemed || false
    },
  },
  mounted () {
    this.loaded = true
  },
  methods: {
    async submitSSL() {
      if (this.sslVoucherRedeemed) {
        this.$emit('next-slide')
      } else {
        this.creatingSSL = true

        const sslCreatedPromise = new Promise((resolve) => {
          this.bus.$once('sslCreated', () => {
            this.successToast('Success', 'SSL has been created.')
            this.$emit('next-slide')
            resolve()
          })
        })

        try {
          this.bus.$emit('submitSslForm')
          await sslCreatedPromise
        } catch (error) {
          // Suppressing the error since it's handled in the ssl service form component
        } finally {
          this.creatingSSL = false
        }
      }
      this.creatingSSL = false
    },
    sslCreationFailed() {
      this.creatingSSL = false
    },
  },
}
</script>
<style scoped lang="scss">
.ssl-service-configuration-slide-container {
  .form-title {
    font-weight: bold;
  }

  .buttons {
    float: right;
  }
}
</style>
